/* Homepage welcome section */

import { Flex, Stack, Text, Center } from "@chakra-ui/react"
import React from "react"
import { FaInfoCircle } from "react-icons/fa"
import { EstimateIcon, FairIcon, QualityIcon } from "../icons"
import HomepageSection from "./section-template"

const WelcomeSection = () => {
  return (
    <HomepageSection
      bgColor="gray.100"
      title="Welcome to Sherpa Home Improvement"
      leftIcon={<FaInfoCircle />}
      to="/about"
      ctaText="Read More About Us"
      isHeroSection
    >
      <Stack direction="column" spacing={4} my={[2, 4]}>
        <Text align="center" fontSize={["md", "lg", "xl"]}>
          <Text as="span" fontWeight="semibold" color="yellow.700">
            {new Date().getFullYear() - 2010}+
          </Text>{" "}
          years experience providing excellent{" "}
          <Text as="span" fontWeight="semibold" color="yellow.700">
            home improvement & repair
          </Text>{" "}
          services in Northern Virginia
        </Text>
        <Center>
          <Stack
            direction={["column", "column", "row"]}
            justify="center"
            spacing={[1, 2, 4, 8]}
          >
            <Flex align="center">
              <QualityIcon />
              <Text fontWeight="semibold" color="teal.700">
                Quality Service
              </Text>
            </Flex>
            <Flex align="center">
              <FairIcon />
              <Text fontWeight="semibold" color="teal.700">
                Fair Pricing
              </Text>
            </Flex>
            <Flex align="center">
              <EstimateIcon />
              <Text fontWeight="semibold" color="teal.700">
                Free Estimate
              </Text>
            </Flex>
          </Stack>
        </Center>
      </Stack>
    </HomepageSection>
  )
}

export default WelcomeSection
