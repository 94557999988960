/* Homepage testimonials section */

import { Center, Icon, SimpleGrid, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaComment, FaSadTear } from "react-icons/fa"
import TestimonialCard from "../testimonial-card"
import HomepageSection from "./section-template"

const TestimonialSection = () => {
  const data = useStaticQuery(graphql`
    query MyQuery($date: HASURA_order_by = desc) {
      hasura {
        testimonial(order_by: { date: $date }) {
          id
          name
          comment
          city
          state
          rating
          date
        }
      }
    }
  `)

  const testimonials = data.hasura.testimonial

  const testimonialsPreview = testimonials
    .filter(review => review.rating >= 5)
    .slice(0, 3)

  return (
    <HomepageSection
      title="Customer Testimonials"
      borderBottom
      leftIcon={<FaComment />}
      to="/testimonials"
      ctaText="View All Testimonials"
    >
      {testimonials.length > 0 ? (
        <Center>
          <SimpleGrid columns={[1, 1, 3]} spacing={[2, 4]} my={[2, 4]}>
            {testimonialsPreview.length > 0 &&
              testimonialsPreview.map((testimonial, index) => (
                <TestimonialCard
                  key={index}
                  rating={testimonial.rating}
                  comment={testimonial.comment}
                  name={testimonial.name}
                  city={testimonial.city}
                  state={testimonial.state}
                  date={testimonial.date}
                />
              ))}
          </SimpleGrid>
        </Center>
      ) : (
        <Center>
          <Text align="center">No testimonials found</Text>
          <Icon as={FaSadTear} w={6} h={6} ml={2} color="blue.400" />
        </Center>
      )}
    </HomepageSection>
  )
}

export default TestimonialSection
