/* Homepage services section */

import { Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaTools } from "react-icons/fa"
import HomepageSection from "./section-template"

const ServiceSection = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "services" } } }
        sort: { fields: frontmatter___title }
      ) {
        nodes {
          frontmatter {
            title
            icon
          }
        }
      }
    }
  `)

  const services = data.allMarkdownRemark.nodes

  return (
    <HomepageSection
      title="Our Services"
      borderBottom
      leftIcon={<FaTools />}
      to="/services"
      ctaText="View Service Details"
    >
      <SimpleGrid columns={[1, 1, 2, 4]} spacing={[2, 4]} my={[2, 4]}>
        {services.map((service, index) => (
          <Flex
            key={index}
            direction="column"
            boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
            borderRadius="8px"
            justify="space-evenly"
            align="center"
            minH={["100px", "124px", "148px"]}
          >
            <Icon viewBox="0 0 24 24" w={12} h={12} color="yellow.500">
              <path fill="currentColor" d={service.frontmatter.icon} />
            </Icon>
            <Text fontSize="lg" fontWeight="semibold" color="teal.700">
              {service.frontmatter.title}
            </Text>
          </Flex>
        ))}
      </SimpleGrid>
    </HomepageSection>
  )
}

export default ServiceSection
