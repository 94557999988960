/* Testimonial card component */

import { Avatar, Box, Flex, HStack, Icon, Text } from "@chakra-ui/react"
import React from "react"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import StarRating from "./display-star-rating"

const TestimonialCard = ({ comment, rating, name, city, state, date }) => {
  return (
    <Flex
      direction="column"
      borderRadius="8px"
      boxShadow="rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"
    >
      <StarRating value={rating} />
      <Flex direction="column" justify="center" align="center" h="100%" p={4}>
        <Icon as={FaQuoteLeft} color="teal" />
        <Text align="center" py={4}>
          {comment}
        </Text>
        <Icon as={FaQuoteRight} color="teal" />
      </Flex>
      <Box bgColor="gray.200">
        <HStack justify="flex-end" py={2} pr={4} spacing={3}>
          <Avatar size="sm" name={name} src="" />
          <Flex direction="column" color="gray.900">
            <Text fontSize="sm" fontWeight="semibold" color="teal.700">
              {name}
            </Text>
            <Text fontSize="xs">
              {city}, {state}
            </Text>
            <Text fontSize="xs">{date}</Text>
          </Flex>
        </HStack>
      </Box>
    </Flex>
  )
}

export default TestimonialCard
