/* Homepage section template */

import {
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Stack,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { FaFileInvoiceDollar } from "react-icons/fa"

const HomepageSection = ({
  title,
  borderBottom = false,
  bgColor,
  children,
  leftIcon,
  to,
  ctaText,
  isHeroSection = false,
}) => {
  return (
    <Flex
      direction="column"
      minH="85vh"
      bgColor={bgColor}
      justify="space-around"
      align="center"
      grow={1}
      py={4}
    >
      <Heading
        as="h1"
        color="teal.700"
        textAlign="center"
        borderBottom={borderBottom ? "2px solid #ecc94b" : "none"}
        my={[2, 4]}
      >
        {title}
      </Heading>
      {isHeroSection && (
        <Center>
          <StaticImage
            src="../../images/logo.png"
            alt="Sherpa Home Improvement company logo"
            layout="fixed"
            width={256}
            height={256}
          />
        </Center>
      )}
      <Container maxW="container.xl" my={[2, 4]}>
        {children}
      </Container>
      <Stack direction={["column", "row"]} my={[2, 4]}>
        <Button
          leftIcon={leftIcon}
          colorScheme="yellow"
          color="teal.800"
          as={GatsbyLink}
          to={to}
          isFullWidth
        >
          {ctaText}
        </Button>
        {isHeroSection && (
          <Button
            leftIcon={<FaFileInvoiceDollar />}
            colorScheme="teal"
            bg="teal.600"
            as={GatsbyLink}
            to="/contact"
            _hover={{ bg: "teal.800" }}
            isFullWidth
          >
            Get Free Estimate
          </Button>
        )}
      </Stack>
    </Flex>
  )
}

export default HomepageSection
