/* Home page */

import React from "react"
import Seo from "../seo"
import ProjectSection from "./project"
import ServiceSection from "./service"
import TestimonialSection from "./testimonial"
import WelcomeSection from "./welcome"

const Homepage = () => {
  return (
    <>
      <Seo title="Home" />
      <WelcomeSection />
      <ServiceSection />
      <ProjectSection />
      <TestimonialSection />
    </>
  )
}

export default Homepage
