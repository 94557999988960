/* Homepage projects section */

import { Box } from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaCamera } from "react-icons/fa"
import ProjectCard from "../project-card"
import HomepageSection from "./section-template"

const ProjectSection = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        filter: { fields: { collection: { eq: "projects" } } }
        sort: { fields: frontmatter___complete, order: DESC }
      ) {
        nodes {
          frontmatter {
            title
            description
            featured
          }
          fields {
            slug
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.nodes

  return (
    <HomepageSection
      title="Completed Projects"
      borderBottom
      bgColor="gray.100"
      leftIcon={<FaCamera />}
      to="/projects"
      ctaText="View All Projects"
    >
      <Box
        sx={{
          columnCount: [1, 2, 2, 4],
          columnGap: ["16px", "16px", "16px", "32px"],
        }}
        my={[2, 4]}
      >
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            title={project.frontmatter.title}
            slug={project.fields.slug}
            featured={project.frontmatter.featured}
          />
        ))}
      </Box>
    </HomepageSection>
  )
}

export default ProjectSection
