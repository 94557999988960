/* Star rating component */

import { Flex, Icon } from "@chakra-ui/react"
import React from "react"
import { FaStar } from "react-icons/fa"

const StarRating = ({ value }) => {
  const StarIcon = ({ active }) => (
    <Icon as={FaStar} color={active ? "yellow.400" : "gray.300"} w={6} h={6} />
  )

  return (
    <Flex justify="center" align="center" pt={4}>
      <StarIcon active={value >= 1} />
      <StarIcon active={value >= 2} />
      <StarIcon active={value >= 3} />
      <StarIcon active={value >= 4} />
      <StarIcon active={value >= 5} />
    </Flex>
  )
}

export default StarRating
